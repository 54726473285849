import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import MenuMobile from "./MenuMobile";

const NavigationWrapper = styled.nav`
  position: fixed;
  top: 0px;
  display: flex;
  alignt-itemms: center;
  justify-content: flex-end;
  z-index: 1000;
  background-color: white;
  height: 80px;
  transition: 0.4s;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 30px;
  top: 5px;
  transition: 0.4s;
  z-index: -1;
  @media (max-width: 767px) {
    z-index: -1000;
  }
`;

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 50px;
  @media (max-width: 1023px) {
    display: none !important;
  }
`;

const NavigationListItem = styled.li`
  font-weight: 600;
  font-size: 16px;
  margin-left: 32px;
  text-transform: uppercase;
`;
const SubList = styled.ul`
   {
    height: 0;
    width: 0;
    opacity: 0;
    transition: 0.4s;
    transform: translateY(-5px);
    padding: 10px auto;
    list-style: none;
    background-color: white;
    position: absolute;
    z-index: 1001;
  }
`;

const Navigation = (props) => {
  const [isNavVisible, setIsNavVisible] = useState("flex");
  const [isNavSmaller, setIsNavSmaller] = useState("80px");
  const [isLogoSmaller, setIsLogoSmaller] = useState("85px");
  const [logoImg, setLogoImg] = useState("logo");
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
    if (showMenu) setLogoImg("logo");
  };

  const updateLogoImg = (newLogoImg) => {
    setLogoImg(newLogoImg);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setIsNavVisible("none");
        setIsNavSmaller("60px");
        setIsLogoSmaller("65px");

        // setLogoImg("logo-white");
      } else {
        setIsNavVisible("flex");
        setIsNavSmaller("80px");
        setIsLogoSmaller("85px");
        if (window.innerWidth > 1023) {
          setLogoImg("logo");
        }
      }
    });
  }, []);

  return (
    <>
      <NavigationWrapper style={{ height: `${isNavSmaller}` }}>
        <Logo
          onClick={() => {
            if (props.logoImg === "logo") {
              return toggleMenu();
            }
          }}
          className="logo"
          style={{ height: `${isLogoSmaller}` }}
        >
          <Link to="/">
            <img src={require(`../../assets/images/${logoImg}.png`).default} />{" "}
          </Link>
        </Logo>
        <NavigationList style={{ display: `${isNavVisible}` }}>
          <NavigationListItem>
            <Link to="/o-nas" className="link">
              o nas
              <SubList className="subList">
                <li>
                  {" "}
                  <Link to="/o-nas#abUs">Kim jesteśmy</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/o-nas#realizations">Relizacje</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/o-nas#aww">Nagrody i wyróżnienia</Link>
                </li>
                <li>
                  <Link to="/o-nas#opinions">Opinie</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/kariera">Kariera</Link>
                </li>
              </SubList>
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/biznes-instytucja" className="link">
              oferta
              <SubList className="subList">
                <li>
                  <Link to="/biznes-instytucja">
                    Biznes <br />i Instytucja
                  </Link>
                </li>
                <li>
                  <Link to="/nowoczesna-edukacja">
                    Nowoczesna <br />
                    Edukacja
                  </Link>
                </li>
                <li>
                  <Link to="/podpis-kwalifikowany">Podpis kwalifikowany</Link>
                </li>
                <li>
                  <Link to="/projektowanie-stron">Projektowanie stron</Link>
                </li>
                <li>
                  <Link to="/kasy-fiskalne-online">Kasy fiskalne</Link>
                </li>
                <li>
                  <Link to="/dzierzawa-druku">Dzierżawa druku</Link>
                </li>
                <li>
                  <Link to="/uslugi-serwisowe">Usługi serwisowe</Link>
                </li>
              </SubList>
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/aktualnosci">aktualności</Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/salony" className="link">
              salon
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="https://jbm.pl">sklep on-line</Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/katalogi">katalogi</Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/kontakt">kontakt</Link>
          </NavigationListItem>
        </NavigationList>
        <MenuMobile
          logoImg={props.logoImg}
          onLogoChange={updateLogoImg}
          onMenuToggle={toggleMenu}
        />
      </NavigationWrapper>
    </>
  );
};

export default Navigation;
