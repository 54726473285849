import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const CloseBtn = styled.li`
  cursor: pointer;
  font-size: 36px !important;
  left: 30%;
  &:hover {
    color: black;
  }
  @media (max-width: 1023px) {
    left: 80%;
  }
`;
const SubList = styled.div`
   {
    height: 0;
    width: auto;
    opacity: 0;
    transition: 0.4s;
    transform: translateY(-5px);
    padding: 10px auto;
    list-style: none;
    // background-color: rgb(243, 240, 240);
    position: absolute;
    z-index: 1000;
    left: 100%;
    top: -500px;
  }
`;
const MenuMobile = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeClass, setActiveClass] = useState("normal");

  const handleClick = () => {
    setShowMenu((prevState) => !prevState);
    const newLogoImgValue = props.logoImg === "logo" ? "logo-white" : "logo";
    props.onLogoChange(newLogoImgValue);
    props.onLogoChange("logo-white");
    props.onMenuToggle();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setActiveClass("active");
      } else {
        setActiveClass("normal");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`menuMobile ${activeClass}`}>
      {/* <span className="logo">
        <Link to="/" activeClassName="active">
          Adam <br />
          Burawski
        </Link>
      </span> */}

      {showMenu ? (
        <nav>
          <ul>
            <CloseBtn onClick={handleClick}>X</CloseBtn>
            <li>
              <Link to="/" onClick={handleClick}>
                Home
              </Link>
            </li>
            <li className="subListNav">
              <Link className="unlink" onClick={handleClick}>
                O nas
                <SubList className="subList">
                  <li>
                    <Link to="/o-nas#abUs" onClick={handleClick}>
                      Kim jesteśmy
                    </Link>
                  </li>
                  <li>
                    <Link to="/o-nas#realizations" onClick={handleClick}>
                      Relizacje
                    </Link>
                  </li>
                  <li>
                    <Link to="/o-nas#aww" onClick={handleClick}>
                      Nagrody i wyróżnienia
                    </Link>
                  </li>
                  <li>
                    <Link to="/o-nas#opinions" onClick={handleClick}>
                      Opinie
                    </Link>
                  </li>
                  <li>
                    <Link to="/kariera" onClick={handleClick}>
                      Kariera
                    </Link>
                  </li>
                </SubList>
              </Link>
            </li>
            <li className="subListNav">
              <Link className="unlink">
                Oferta
                <SubList className="subList">
                  <li>
                    <Link to="/biznes-instytucja" onClick={handleClick}>
                      Biznes i Instytucja
                    </Link>
                  </li>
                  <li>
                    <Link to="/nowoczesna-edukacja" onClick={handleClick}>
                      Nowoczesna edukacja
                    </Link>
                  </li>
                  <li>
                    <Link to="/podpis-kwalifikowany" onClick={handleClick}>
                      Podpis kwalifikowany
                    </Link>
                  </li>
                  <li>
                    <Link to="/projektowanie-stron" onClick={handleClick}>
                      Projektowanie stron
                    </Link>
                  </li>
                  <li>
                    <Link to="/kasy-fiskalne-online" onClick={handleClick}>
                      Kasy fiskalne
                    </Link>
                  </li>
                  <li>
                    <Link to="/dzierzawa-druku" onClick={handleClick}>
                      Dzierżawa druku
                    </Link>
                  </li>
                  <li>
                    <Link to="/uslugi-serwisowe" onClick={handleClick}>
                      Usługi serwisowe
                    </Link>
                  </li>
                </SubList>
              </Link>
            </li>
            <li>
              <Link to="/aktualnosci" onClick={handleClick}>
                Aktualności
              </Link>
            </li>
            <li className="subListNav">
              <Link to="/salony" onClick={handleClick}>
                Salon
              </Link>
            </li>
            <li>
              <Link to="https://jbm.pl" onClick={handleClick}>
                Sklep on-line
              </Link>
            </li>
            <li>
              <Link to="/katalogi" onClick={handleClick}>
                Katalogi
              </Link>
            </li>
            <li>
              <Link to="/kontakt" onClick={handleClick}>
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <div className={`menuIcon ${activeClass}`} onClick={handleClick}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {/* {showMenu ? (navigation = { style: transStart }) : (navigation = { style: transEnd })} */}
    </nav>
  );
};

export default MenuMobile;
