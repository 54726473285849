exports.components = {
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-ambasada-kawy-js": () => import("./../../../src/pages/ambasada-kawy.js" /* webpackChunkName: "component---src-pages-ambasada-kawy-js" */),
  "component---src-pages-biznes-instytucja-js": () => import("./../../../src/pages/biznes-instytucja.js" /* webpackChunkName: "component---src-pages-biznes-instytucja-js" */),
  "component---src-pages-dzierzawa-druku-js": () => import("./../../../src/pages/dzierzawa-druku.js" /* webpackChunkName: "component---src-pages-dzierzawa-druku-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kasy-fiskalne-online-js": () => import("./../../../src/pages/kasy-fiskalne-online.js" /* webpackChunkName: "component---src-pages-kasy-fiskalne-online-js" */),
  "component---src-pages-katalogi-js": () => import("./../../../src/pages/katalogi.js" /* webpackChunkName: "component---src-pages-katalogi-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nowoczesna-edukacja-js": () => import("./../../../src/pages/nowoczesna-edukacja.js" /* webpackChunkName: "component---src-pages-nowoczesna-edukacja-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-podpis-kwalifikowany-js": () => import("./../../../src/pages/podpis-kwalifikowany.js" /* webpackChunkName: "component---src-pages-podpis-kwalifikowany-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projektowanie-stron-js": () => import("./../../../src/pages/projektowanie-stron.js" /* webpackChunkName: "component---src-pages-projektowanie-stron-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-salony-js": () => import("./../../../src/pages/salony.js" /* webpackChunkName: "component---src-pages-salony-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-uslugi-serwisowe-js": () => import("./../../../src/pages/uslugi-serwisowe.js" /* webpackChunkName: "component---src-pages-uslugi-serwisowe-js" */)
}

