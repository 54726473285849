import * as React from "react";
import Navigation from "../components/Navigation/Navigation";
import Seo from "../components/seo";

const MainLayout = ({ children }) => (
  // <Layout>
  <>
    <Seo title="Firma JB Multimedia Biała Podlaska Laptopy, Drukarki, AGD" />
    <Navigation />
    {children}
  </>
  // </Layout>
);

export default MainLayout;
